import { useMemo } from 'react';

import { useAppSelector } from 'store/createStore';
import { selectClientInfo, selectUserInfo } from 'store/features/mainSlice';
import { FeatureFlagsInterface } from 'store/features/mainSliceTypes';
import { SettingsStateInterface } from 'store/features/settingsSlice';

import { checkDisplayConditions } from './displayConditionParser';
import { MenuData, MenuItemType } from './MenuItems.types';
import { getSideBarItems } from './sideBarMenuItems';
import { getTopBarItems } from './topBarMenuItems';

interface MenuItems {
  topBarMenuItems: MenuItemType[];
  sideBarMenuItems: MenuItemType[];
}

// Function to apply default values recursively
const applyDefaults = (item: MenuItemType): MenuItemType => ({
  ...item,
  sideNavEntryPoint: !!item.sub?.every((subItem) => subItem.displayAsTab),
  displayAsTab: item.displayAsTab ?? false,
  sub: item.sub?.map(applyDefaults),
});

// Recursively filter menu items based on display conditions
const filterMenuItems = (
  items: MenuItemType[],
  featureFlags: FeatureFlagsInterface,
  cybsafeSettings: SettingsStateInterface,
  isDummyClient: boolean,
  userGroup?: number,
): MenuItemType[] => {
  return items
    .filter((item) =>
      checkDisplayConditions({
        displayConditions: item.displayConditions,
        featureFlags,
        cybsafeSettings,
        isDummyClient,
        userGroup,
        url: item.url,
      }),
    )
    .map((item) => ({
      ...item,
      label: item.label || '',
      sub: item.sub
        ? filterMenuItems(
            item.sub,
            featureFlags,
            cybsafeSettings,
            isDummyClient,
            userGroup,
          )
        : undefined,
    }));
};

const useMenuItems = (): MenuItems => {
  // Get all required data from Redux store
  const featureFlags = useAppSelector((state) => state.main.featureFlags);
  const cybsafeSettings = useAppSelector((state) => state.userSettings);
  const { userGroup, id, publicId } = useAppSelector(selectUserInfo);
  const clientInfo = useAppSelector(selectClientInfo);

  // Memoize the processed menu items
  const menuItems = useMemo(() => {
    const menuData: MenuData = {
      topBarMenuItems: getTopBarItems(id, publicId),
      sideBarMenuItems: getSideBarItems(clientInfo),
    };
    // Filter and apply defaults to top bar menu items
    const filteredTopBarItems = filterMenuItems(
      menuData.topBarMenuItems,
      featureFlags,
      cybsafeSettings,
      clientInfo.isDummy ?? false,
      userGroup,
    );
    const processedTopBarItems = filteredTopBarItems.map((item) =>
      applyDefaults({
        ...item,
        active: item.url === window.location.pathname,
      }),
    );

    // Filter and apply defaults to side bar menu items
    const filteredSideBarItems = filterMenuItems(
      menuData.sideBarMenuItems,
      featureFlags,
      cybsafeSettings,
      clientInfo.isDummy ?? false,
      userGroup,
    );
    const processedSideBarItems = filteredSideBarItems.map((item) =>
      applyDefaults({
        ...item,
        active: item.url === window.location.pathname,
      }),
    );

    return {
      topBarMenuItems: processedTopBarItems,
      sideBarMenuItems: processedSideBarItems,
    };
  }, [id, publicId, clientInfo, featureFlags, cybsafeSettings, userGroup]);

  return menuItems;
};

export default useMenuItems;
