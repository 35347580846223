import React, { ReactNode } from 'react';

import { useIntl } from 'react-intl';

import { MenuItemType } from 'components/Layout/MenuData/MenuItems.types';

import { CustomTag } from '../SideNav.styles';

type TextWithTags = {
  title: ReactNode | string;
  tags?: MenuItemType['tags'];
  className?: string;
};

const MenuTextWithTags = ({ title, tags, className }: TextWithTags) => {
  const intl = useIntl();
  return (
    <span className={className}>
      {title}{' '}
      {tags?.map((tag) => (
        <CustomTag type={tag.color} key={tag.name}>
          {intl.formatMessage({ id: tag.name })}
        </CustomTag>
      ))}
    </span>
  );
};

export default MenuTextWithTags;
