import React from 'react';

import {
  Analytics,
  Book,
  Events,
  Integration,
  MeterAlt,
  Settings,
} from '@carbon/icons-react';

import Guide from 'components/CustomIcons/Guide';
import Phish from 'components/CustomIcons/Phish';
import Respond from 'components/CustomIcons/Respond';

const CUSTOM_MENU_ICONS = {
  guide: <Guide />,
  phish: <Phish />,
  respond: <Respond />,
};

const MenuItemIcon = ({ icon }: { icon: string }) => {
  const isCustomIcon = Object.keys(CUSTOM_MENU_ICONS).includes(icon);
  if (isCustomIcon) {
    return CUSTOM_MENU_ICONS[icon];
  }
  // Temporary override to icons to use Carbon, this will be removed in the future when we move to menu structure from code.
  switch (icon) {
    case 'tachometer-alt':
      return <MeterAlt />;
    case 'chart-area':
      return <Analytics />;
    case 'users':
      return <Events />;
    case 'integrations':
      return <Integration />;
    case 'cog':
      return <Settings />;
    case 'book-reader':
      return <Book />;
    default:
      return null;
  }
};

export default MenuItemIcon;
