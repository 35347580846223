import React from 'react';

type BlockAdminContextType = {
  state: { display: boolean };
  dispatch: React.Dispatch<any>;
};

const BlockAdminContext = React.createContext<BlockAdminContextType>({
  state: { display: false },
  dispatch: () => {},
});

export const useBlockAdminContext = () => React.useContext(BlockAdminContext);

export default BlockAdminContext;
