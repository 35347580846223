import React, { useImperativeHandle, useRef } from 'react';

import { useIntl } from 'react-intl';

import { PersonalisationItem } from 'components/Component.types';
import { Box, FlexBox } from 'components/Containers';
import { isLabel } from 'utils';

import { InputWrapper } from '../Inputs.styles';
import PersonaliseBarItem from '../Personalisation/BarItem';

import { TArea } from './TextArea.styles';

export type TextAreaProps = {
  id: string;
  label?: string | React.ReactElement;
  onChangeHandler?: (arg0: string) => void;
  className?: string;
  name?: string;
  placeholder?: string;
  helperText?: string | React.ReactElement;
  hideLabel?: boolean;
  invalidLabel?: string;
  invalid?: boolean;
  value?: string;
  disabled?: boolean;
  dontTranslateLabel?: boolean;
  personalisationItems?: PersonalisationItem[];
  showPersonalisationIcon?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      className,
      name,
      label,
      hideLabel,
      placeholder,
      invalidLabel,
      invalid,
      onChangeHandler,
      onBlur,
      onFocus,
      disabled,
      dontTranslateLabel,
      personalisationItems,
      showPersonalisationIcon = true,
      ...rest
    },
    ref,
  ) => {
    const intl = useIntl();
    const _ref = useRef<HTMLTextAreaElement>();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => _ref.current!, []);

    return (
      <FlexBox>
        <InputWrapper>
          <TArea
            ref={_ref}
            id={`${id}-textarea`}
            className={className}
            name={name}
            labelText={
              label
                ? dontTranslateLabel
                  ? label
                  : intl.formatMessage({
                      id: `label_${label}`,
                    })
                : ''
            }
            hideLabel={hideLabel}
            placeholder={placeholder}
            invalidText={
              invalidLabel && isLabel(invalidLabel)
                ? intl.formatMessage({ id: invalidLabel })
                : invalidLabel
            }
            invalid={invalid}
            onChange={(event) => {
              onChangeHandler && onChangeHandler(event.target.value);
            }}
            disabled={disabled}
            aria-label={
              label
                ? intl.formatMessage({
                    id: `${label}`,
                  })
                : ''
            }
            onBlur={(event) => {
              onBlur && onBlur(event);
            }}
            onFocus={(event) => {
              onFocus && onFocus(event);
            }}
            {...rest}
          />
        </InputWrapper>
        {personalisationItems && personalisationItems.length > 0 && (
          <Box aligns="flex-start" t="6px" pos="relative">
            <PersonaliseBarItem
              showPersonalisationIcon={showPersonalisationIcon}
              personalisationItems={personalisationItems}
              onPersonalisationSelected={(option: string) => {
                // insert the selected personalisation option at the current cursor position
                const currentRef = _ref;
                if (currentRef) {
                  const textArea = currentRef.current;
                  if (!textArea) {
                    return;
                  }
                  const cursorPosition = textArea.selectionStart;
                  const textBeforeCursorPosition = textArea.value.substring(
                    0,
                    cursorPosition,
                  );
                  const textAfterCursorPosition = textArea.value.substring(
                    cursorPosition,
                    textArea.value.length,
                  );
                  const newValue = `${textBeforeCursorPosition}${option}${textAfterCursorPosition}`;
                  textArea.value = newValue;
                  onChangeHandler && onChangeHandler(newValue);
                }
              }}
            />
          </Box>
        )}
      </FlexBox>
    );
  },
);

export default Textarea;
