import React, { ComponentType, ErrorInfo, PureComponent } from 'react';

import { Login, Restart } from '@carbon/icons-react';
import { Button as CarbonButton } from '@carbon/react';
import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';

import Button from 'components/Button';
import Translation from 'components/Content/Translation/Translation';
import { Wrapper } from 'components/Wrappers';

import { unit } from '../../theme';
import { FlexBox } from '../Containers';

import { ErrorContainer, MobileErrorContainer } from './Error.styles';

type State = {
  error: Error | null | undefined;
};

export type APIError = {
  status: number;
  response: {
    requestId?: string;
    message?: string;
  };
};

type ErrorProp = APIError;

export type FallbackErrorComponentProps = {
  error?: ErrorProp | null;
  title?: string;
  withRetryButton?: boolean;
  retryButtonCallback?: () => void;
  removeBorder?: boolean;
};

const ERROR_STATUS_LABELS: Record<number, string> = {
  400: 'common.error.badRequest',
  401: 'common.error.unauthorized',
  403: 'common.error.forbidden',
  404: 'common.error.notFound',
  500: 'common.error.internalServerError',
};

const getErrorHeading = (
  title: string | undefined,
  error?: ErrorProp | null,
) => {
  if (title) return title;
  if (error?.status && ERROR_STATUS_LABELS[error.status])
    return ERROR_STATUS_LABELS[error.status];
  return 'label_someThingWentWrong';
};

const ErrorResponse = ({ error }: { error: ErrorProp }) => {
  return (
    <>
      {error.status && (
        <p>
          <span>{`Status: ${error.status}`}</span>
        </p>
      )}
      {error.response?.requestId && (
        <p>
          <span>{`Request Id: ${error.response.requestId}`}</span>
        </p>
      )}
    </>
  );
};

export const FallbackErrorComponent = ({
  error,
  title,
  withRetryButton,
  retryButtonCallback,
  removeBorder,
}: FallbackErrorComponentProps) => {
  const intl = useIntl();
  return (
    <Wrapper testId="error-section" removeBorder={removeBorder}>
      <ErrorContainer>
        <h4>
          {intl.formatMessage({
            id: getErrorHeading(title, error),
            defaultMessage: 'Something went wrong',
          })}
        </h4>
        <img
          src="https://s3-eu-west-1.amazonaws.com/cybsafe-resources/robot-not-found.png"
          alt=""
        />
        {error && (
          <>
            {error.status || error.response?.requestId ? (
              <ErrorResponse error={error} />
            ) : (
              <Button
                href={`${window.location.origin}/`}
                label="component.error.button.backHome"
              />
            )}
          </>
        )}
        {withRetryButton && (
          <Button
            onClick={retryButtonCallback}
            data-testid="error-retry-button"
            label="common.button.retry"
          />
        )}
      </ErrorContainer>
    </Wrapper>
  );
};

export const FallbackErrorMobileComponent = () => (
  <MobileErrorContainer>
    <h4>
      <Translation id="label_someThingWentWrong" />
    </h4>
    <img
      src="https://s3-eu-west-1.amazonaws.com/cybsafe-resources/robot-not-found.png"
      alt=""
    />
  </MobileErrorContainer>
);

// Error used when the bootstrap call fails, which means the core lang is not called so no formatted messages used
export const FallbackErrorNoLanguage = ({
  error,
}: Pick<FallbackErrorComponentProps, 'error'>) => (
  <Wrapper testId="error-section-no-language">
    <ErrorContainer>
      <h4>Oops! That didn&apos;t work for some reason.</h4>
      <img
        src="https://s3-eu-west-1.amazonaws.com/cybsafe-resources/robot-not-found.png"
        alt=""
      />
      {error?.status ||
        (error?.response?.requestId && <ErrorResponse error={error} />)}
      <FlexBox mt={unit(4)} gap={unit(10)}>
        {/* We use the buttons straight from Carbon here due to our button component
        using react-intl which, at this point of use, isn't available */}
        <CarbonButton
          kind="tertiary"
          size="md"
          renderIcon={Login}
          onClick={() => {
            window.location.href = '/login';
          }}
        >
          Back to login
        </CarbonButton>
        <CarbonButton
          kind="primary"
          size="md"
          renderIcon={Restart}
          onClick={() => {
            window.location.reload();
          }}
        >
          Retry
        </CarbonButton>
      </FlexBox>
    </ErrorContainer>
  </Wrapper>
);

type ErrorBoundaryProps = {
  children?: React.ReactNode;
  onError?: (error: Error, componentStack: string) => void;
};

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, State> {
  static defaultProps = {
    FallbackComponent: FallbackErrorComponent,
  };
  state = {
    error: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        /* istanbul ignore next: Ignoring ternary; can’t reproduce missing info in test environment. */
        onError.call(this, error, info?.componentStack ?? '');
      } catch (ignoredError) {
        // eslint-disable-next-line no-console
        console.log(ignoredError);
      }
    }

    Sentry.captureException(error);
    this.setState({
      error,
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error !== null) {
      return <FallbackErrorComponent error={error} />;
    }

    return children || null;
  }
}

export const withErrorBoundary = <P extends object>(
  Component: ComponentType<P>,
): ComponentType<P> => {
  const Wrapped: React.FC<P> = (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

  const name = Component.displayName || Component.name;
  Wrapped.displayName = name
    ? `WithErrorBoundary(${name})`
    : 'WithErrorBoundary';

  return Wrapped;
};
