import React, { ReactElement, Suspense, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/createStore';
import { selectBottomBarHeight } from 'store/features/configSlice';
import {
  clearDelegateAccessClientId,
  selectClientInfo,
  selectUserInfo,
  setFullPageLoadingScreen,
  setUrlFeaturesMap,
} from 'store/features/mainSlice';
import { selectLogo } from 'store/features/themeSlice';
import {
  useBreakpoints,
  useDirection,
  useHasDelegatedAccessEnabled,
  usePrevious,
} from 'utils/hooks';

import { useFeatureFlag } from '../../feature-flags';
import { useSubscriptionContext } from '../Subscription';
import SubscriptionBanner from '../Subscription/SubscriptionFullPageLevel/components/SubscriptionBanner';

import DelegatedAccessHeader from './components/DelegatedAccessHeader';
import Footer from './Footer';
import Header from './Header/Header';
import {
  Content,
  ContentWrapper,
  CustomHeader,
  Wrapper,
} from './Layout.styles';
import useMenuItems from './MenuData';
import SideNav from './SideNav/SideNav';

export type LayoutProps = {
  title?: string;
  children: ReactElement;
  narrowWidth?: boolean;
  withUserMenu?: boolean;
  withSideMenu?: boolean;
  withTopMenu?: boolean;
  isTransparent?: boolean;
  noHeader?: boolean;
  useImprovedDelegatedAccess: boolean;
};

const Layout = ({
  children,
  narrowWidth,
  withUserMenu,
  withSideMenu = false,
  withTopMenu = false,
  isTransparent,
  noHeader,
  useImprovedDelegatedAccess,
}: LayoutProps) => {
  const isConnectApp = localStorage.getItem('isConnectApp');
  const useIconSideNav = useFeatureFlag('use-icon-side-nav');
  const direction = useDirection();
  const location = useLocation();
  const { carbonLayout } = useBreakpoints('carbonLayout');
  const prevCarbonLayout = usePrevious(carbonLayout);

  const { level, hasNoSubscription } = useSubscriptionContext();

  const [isSideNavExpanded, setSideNavExpand] = useState(false);
  const [iconMenuExpanded, setIconMenuExpanded] = useState(false);
  const { isAdmin, userGroup } = useAppSelector(selectUserInfo);
  const bottomBarHeight = useAppSelector(selectBottomBarHeight);
  const logo = useAppSelector(selectLogo);
  const dispatch = useAppDispatch();
  const clientInfo = useAppSelector(selectClientInfo);

  const hasDelegatedAccessEnabled = useHasDelegatedAccessEnabled();

  const menuItems = useMenuItems();

  useEffect(() => {
    if (isSideNavExpanded && isConnectApp) setSideNavExpand(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    /* This fixes an issue where if the menu is open on a small screen and then
    the screen is enlarged, the menu is stuck open. */
    if (prevCarbonLayout && !carbonLayout) {
      setSideNavExpand(false);
    }
  }, [carbonLayout, prevCarbonLayout]);

  return (
    <Wrapper
      $isTransparent={isTransparent}
      $hasDelegatedAccess={hasDelegatedAccessEnabled}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <>
          {hasDelegatedAccessEnabled && (
            <DelegatedAccessHeader
              name={clientInfo.name ?? ''}
              onResetDelegatedAccess={async () => {
                dispatch(
                  setFullPageLoadingScreen(
                    'loadingSpinner.resetDelegatedAccess',
                  ),
                );
                await dispatch(clearDelegateAccessClientId());
                const url = new URL(window.location.href);
                if (url.searchParams.has('delegated_access_client_id')) {
                  url.searchParams.delete('delegated_access_client_id');
                  window.history.pushState({}, '', url);
                }
                window.location.reload();
              }}
            />
          )}
          {!noHeader && (
            <CustomHeader
              aria-label="CybSafe"
              $delegatedAccess={hasDelegatedAccessEnabled}
            >
              <Header
                onClickSideNavExpand={setSideNavExpand}
                isSideNavExpanded={isSideNavExpanded}
                menuItems={menuItems.topBarMenuItems}
                withTopMenu={withTopMenu}
                withUserMenu={withUserMenu}
                withSideMenu={withSideMenu}
                headerLogo={logo}
                useImprovedDelegatedAccess={useImprovedDelegatedAccess}
              />
              <SideNav
                isSideNavExpanded={isSideNavExpanded}
                menuItems={menuItems.sideBarMenuItems}
                headerItems={menuItems.topBarMenuItems}
                hasCustomLogo={!!logo}
                withSideMenu={withSideMenu}
                withTopMenu={withTopMenu}
                direction={direction}
                isAdmin={isAdmin ?? false}
                userGroup={userGroup}
                hasDelegatedAccess={hasDelegatedAccessEnabled}
                updateFeatureMap={(urlFeatureMap) =>
                  dispatch(setUrlFeaturesMap(urlFeatureMap))
                }
                setIconMenuExpanded={setIconMenuExpanded}
                iconMenuExpanded={iconMenuExpanded}
              />
            </CustomHeader>
          )}
          <ContentWrapper
            $isAdmin={isAdmin || userGroup === 7}
            $withSideMenu={withSideMenu}
            $direction={direction}
            $iconMenuExpanded={iconMenuExpanded}
            $useIconSideNav={useIconSideNav}
          >
            {hasNoSubscription && level && <SubscriptionBanner level={level} />}
            <Content
              id="main-content"
              narrowWidth={narrowWidth}
              noHeader={noHeader}
              $bottomHeight={bottomBarHeight}
              $hasDelegatedAccess={hasDelegatedAccessEnabled}
            >
              {children}
              <Footer />
            </Content>
          </ContentWrapper>
        </>
      </Suspense>
    </Wrapper>
  );
};

export default Layout;
