import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { useApiData, useApiGet } from 'api';
import { FallbackErrorNoLanguage } from 'components/Error';
import LoaderBlock from 'components/Spinner/LoaderBlock/LoaderBlock';
import { useAppDispatch } from 'store/createStore';
import {
  updateConfigWithStoredLanguage,
  updateConfigWithUserDefaultLanguage,
} from 'store/features/configSlice';
import {
  setBootstrapData,
  setCubeGroupId,
  setPackagesData,
  setSkipIaAndCulture,
} from 'store/features/mainSlice';
import { setSettings } from 'store/features/settingsSlice';
import { useMount } from 'utils/hooks';
import { getCookie } from 'utils/token';

import { FullPageWrapper } from './Bootstrap.styles';

interface BootstrapProps {
  children?: React.ReactNode;
}

const Bootstrap = ({ children }: BootstrapProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState(null);

  const [getBootstrap, { loading: bootstrapLoading, data: bootstrapData }] =
    useApiGet('/api/v1/users/r3/bootstrap/', {
      cache: true,
      reduxAction: setBootstrapData,
    });

  const [getPackageData] = useApiGet('/api/v1/product/packages/r1/', {
    cache: true,
    reduxAction: setPackagesData,
    dontAppendClientId: true,
    dontAppendLocale: true,
    dontAppendSlash: true,
  });

  const [loadSettings] = useApiGet(
    '/api/v1/cybsafe-settings/r1/active-settings/',
    { reduxAction: setSettings },
  );

  const { data: languagesData, loading: languagesLoading } = useApiData(
    '/api/v1/language/r1',
    {
      cache: true,
      dontAppendClientId: true,
      dontAppendLocale: true,
    },
  );

  // Get the correct group id for demo data from the dedicated endpoint.
  const [cubeGroupData] = useApiGet('/api/v1/cube-group-id/r1/', {
    cache: true,
    reduxAction: setCubeGroupId,
  });

  const [skipIaAndCultureData] = useApiGet(
    '/api/v1/user-metadata/r1/skip-ia-and-culture/',
    {
      reduxAction: setSkipIaAndCulture,
    },
  );

  const getAllBootstrapData = async () => {
    try {
      setIsBusy(true);
      const response = await getBootstrap();
      /* We need the bootstrap data to be completed before doing the other api calls */
      if (response) {
        await Promise.all([loadSettings(), getPackageData()]);
        /* Use Promise.allSettled here as it will resolve the promises even if there is an error.
        This is because these api calls should not hinder the users experience if they error. */
        await Promise.allSettled([cubeGroupData(), skipIaAndCultureData()]);
      }
    } catch (e) {
      Sentry.captureException(e);
      setError(e);
    } finally {
      setIsBusy(false);
    }
  };

  useMount(() => {
    if (getCookie() && location.pathname !== '/login') {
      getAllBootstrapData();
    }
  });

  useEffect(() => {
    if (bootstrapData && languagesData) {
      dispatch(updateConfigWithUserDefaultLanguage(languagesData));
    }
  }, [bootstrapData, languagesData, dispatch]);

  useEffect(() => {
    if (!bootstrapLoading && bootstrapData === null && languagesData) {
      dispatch(updateConfigWithStoredLanguage(languagesData));
    }
  }, [bootstrapLoading, bootstrapData, languagesData, dispatch]);

  if (bootstrapLoading || languagesLoading || isBusy) {
    return (
      <FullPageWrapper>
        <LoaderBlock />
      </FullPageWrapper>
    );
  }
  if (error) {
    return <FallbackErrorNoLanguage error={error} />;
  }
  return <>{children}</>;
};

export default Bootstrap;
